<template>
	<div id="app" class="full-container">
		<router-view />
	</div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
	name: 'App',

	setup() {
		// TODO	
	}
})
</script>

<style lang="scss" scoped>
#app {
	display: flex;
	justify-content: center;
	align-items: center;
}
</style>